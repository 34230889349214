import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_textarea = _resolveComponent("edit-textarea")!
  const _component_status_pointed_badge = _resolveComponent("status-pointed-badge")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_edit_textarea, {
      isEditing: _ctx.isEditing,
      editable: _ctx.hasEditPermission,
      error: _ctx.error,
      isLoading: _ctx.isLoading,
      showTranslation: _ctx.showTranslation,
      modelValue: _ctx.currentTitle,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTitle) = $event)),
      backgroundClass: _ctx.backgroundClass,
      placeholder: "Edit Answer",
      user: _ctx.user,
      created_by_creator: _ctx.created_by_creator,
      onOnClose: _ctx.onClose,
      onOnSubmit: _ctx.onSubmit,
      onOnDelete: _ctx.onDelete,
      onOnEdit: _ctx.onEdit
    }, null, 8, ["isEditing", "editable", "error", "isLoading", "showTranslation", "modelValue", "backgroundClass", "user", "created_by_creator", "onOnClose", "onOnSubmit", "onOnDelete", "onOnEdit"]),
    (!_ctx.isEditing)
      ? (_openBlock(), _createBlock(_component_status_pointed_badge, {
          key: 0,
          status: _ctx.currentStatus,
          user_id: _ctx.user.uid,
          title: "answer"
        }, null, 8, ["status", "user_id"]))
      : _createCommentVNode("", true)
  ], 64))
}