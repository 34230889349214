import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/pencil.svg'


const _hoisted_1 = { class: "myarticle" }
const _hoisted_2 = { class: "aticle_image" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "media-body" }
const _hoisted_6 = { class: "media_head" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "mt-0" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 0,
  class: "accord-item-edit"
}
const _hoisted_11 = { class: "d-flex px-3 media_input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_avatar = _resolveComponent("user-avatar")!
  const _component_edit_input = _resolveComponent("edit-input")!
  const _component_status_pointed_badge = _resolveComponent("status-pointed-badge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isEditing)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass('media ' + _ctx.backgroundClass)
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("a", {
              href: _ctx.currentResource.url,
              target: "_blank",
              ref: "noopener nofollow"
            }, [
              _createElementVNode("img", {
                class: "mr-3",
                src: _ctx.currentResource.thumbnail,
                alt: ""
              }, null, 8, _hoisted_4)
            ], 8, _hoisted_3)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_user_avatar, {
                user: _ctx.user,
                showPreview: true,
                showDefaultImage: _ctx.showAvatarDefaultImage
              }, null, 8, ["user", "showDefaultImage"]),
              _createElementVNode("a", {
                href: _ctx.currentResource.url,
                target: "_blank",
                ref: "noopener nofollow"
              }, [
                _createElementVNode("h5", _hoisted_8, _toDisplayString(_ctx.currentResource.title), 1)
              ], 8, _hoisted_7)
            ]),
            (_ctx.currentResource.snippet)
              ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.currentResource.snippet.substring(0, 350) + "..."), 1))
              : _createCommentVNode("", true)
          ]),
          (_ctx.hasEditPermission)
            ? (_openBlock(), _createElementBlock("button", _hoisted_10, [
                _createElementVNode("img", {
                  src: _imports_0,
                  class: "pencilEdit",
                  alt: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onEdit()))
                })
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_11, [
      (_ctx.isEditing)
        ? (_openBlock(), _createBlock(_component_edit_input, {
            key: 0,
            isEditing: _ctx.isEditing,
            editable: _ctx.hasEditPermission,
            error: _ctx.error,
            isLoading: _ctx.isLoading,
            modelValue: _ctx.currentUrl,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentUrl) = $event)),
            backgroundClass: _ctx.backgroundClass,
            created_by_creator: _ctx.created_by_creator,
            placeholder: "Edit Resource",
            onOnDelete: _ctx.onDelete,
            onOnClose: _ctx.onClose,
            onOnSubmit: _ctx.onSubmit,
            user: _ctx.user,
            onOnEdit: _ctx.onEdit
          }, null, 8, ["isEditing", "editable", "error", "isLoading", "modelValue", "backgroundClass", "created_by_creator", "onOnDelete", "onOnClose", "onOnSubmit", "user", "onOnEdit"]))
        : _createCommentVNode("", true),
      (!_ctx.isEditing)
        ? (_openBlock(), _createBlock(_component_status_pointed_badge, {
            key: 1,
            status: _ctx.currentStatus,
            user_id: _ctx.user.uid,
            title: "resource"
          }, null, 8, ["status", "user_id"]))
        : _createCommentVNode("", true)
    ])
  ]))
}