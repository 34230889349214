
import { PropType } from "vue";
import { defineComponent } from "@vue/runtime-core";
import {
  getResources,
  getResourceToValidate,
} from "@toppick/common/build/api/resource";
import { Resource } from "@/interfaces/topics";
import { ActionStatus } from "@/interfaces/ui";
import GuideToolTip from "../../topic/GuideToolTip.vue";
import ResourceSubmitInput from "./ResourceSubmitInput.vue";
import ResourceCard from "./ResourceCard.vue";
import ResourceToValidate from "./ResourceToValidate.vue";
import { getAuthToken } from "@/utils/auth";

const RESOURCES_LOADED_COUNT = 3;

export default defineComponent({
  emits: ["onShowAuthModal"],
  components: {
    GuideToolTip,
    ResourceSubmitInput,
    ResourceCard,
    ResourceToValidate,
  },
  props: {
    resources: {
      type: Array as PropType<Resource[]>,
      required: true,
    },
    questionId: {
      type: Number,
      required: true,
    },
    resourcesCount: {
      type: Number,
      required: true,
    },
    resource_validate: {
      type: Object as PropType<Resource>,
      required: false,
    },
  },
  data() {
    return {
      isShowAddResource: false,
      addIcon: require("@/assets/images/add-icon.svg"),
      resourseIcon: require("@/assets/images/settings.svg"),
      resourceToValidate: this.resource_validate as Resource | null,
      currentResources: [] as Resource[],
      currentResourcesCount: this.resourcesCount,
      isLoading: false,
      isLoadingMoreResources: false,
      isResourceToValidateLoading: false,
    };
  },
  methods: {
    toggleAddResource() {
      if (!this.$store.getters.getIsAuthenticated) {
        this.$emit("onShowAuthModal");
        return;
      }
      this.isShowAddResource = !this.isShowAddResource;
    },
    onAddResource(resource: Resource) {
      resource.action_status = ActionStatus.ADDED;
      this.currentResources.push({ ...resource, users: this.currentUser });
      this.isShowAddResource = false;
    },

    async loadResourceToValidate() {
      this.resourceToValidate = null;
      this.isResourceToValidateLoading = true;
      if (this.$store.getters.getIsAuthenticated) {
        try {
          this.resourceToValidate = await getResourceToValidate(
            await getAuthToken(),
            {
              question_id: this.questionId,
            }
          );
        } catch (error) {
          console.log(error);
        }
      }
      this.isResourceToValidateLoading = false;
    },

    onDeleteResource(id: number) {
      const newResources = this.currentResources.filter((res) => res.id !== id);
      this.currentResources = newResources;
      this.currentResourcesCount = this.currentResourcesCount - 1;
    },
    async loadMoreResources() {
      if (this.isLoadingMoreResources) return;
      this.isLoadingMoreResources = true;
      const newResources = await getResources(await getAuthToken(), {
        question_id: this.questionId,
        limit: RESOURCES_LOADED_COUNT,
        skip: this.currentResources.length,
      });
      this.currentResources = [...this.currentResources, ...newResources];
      this.isLoadingMoreResources = false;
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUserFeatured;
    },
    isShowEmptyBox() {
      return (
        this.currentResources.length <= 0 &&
        !this.resourceToValidate &&
        !this.isShowAddResource &&
        !this.isResourceToValidateLoading
      );
    },
    isShowResourceToValidate() {
      return (
        (this.$store.getters.getIsAuthenticated && !!this.resourceToValidate) ||
        this.isResourceToValidateLoading
      );
    },
    isShowLoadMore() {
      return (
        !this.isShowAddResource &&
        this.currentResources.length < this.currentResourcesCount
      );
    },
  },
  mounted() {
    this.$watch(
      () => this.resources,
      () => {
        this.currentResources = this.resources;
      },
      { immediate: true }
    );
  },
});
