import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cmn_badge"
}
const _hoisted_2 = { class: "badge_text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", {
          class: _normalizeClass('dot ' + _ctx.backgroundClass)
        }, null, 2),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.tooltipText), 1)
      ]))
    : _createCommentVNode("", true)
}