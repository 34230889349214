
import { PropType } from "vue";
import { defineComponent } from "@vue/runtime-core";
import { validateURL } from "@toppick/common/build/validators";
import {
  deleteResource,
  updateResource,
} from "@toppick/common/build/api/resource";
import { ActionStatus } from "@/interfaces/ui";
import EditInput from "../EditInput.vue";
import {
  getAuthToken,
  hasEditPermission,
  hasPriviledgedRole,
} from "@/utils/auth";
import {
  UserFeatured,
  ValidationStatus,
} from "@toppick/common/build/interfaces";
import UserAvatar from "@/components/ui/UserAvatar.vue";
import { Resource } from "@/interfaces/topics";
import StatusPointedBadge from "@/components/ui/badges/StatusPointedBadge.vue";
import { getErrorMessage } from "@toppick/common/build/utils";

export default defineComponent({
  emits: ["onDelete"],
  components: { EditInput, UserAvatar, StatusPointedBadge },
  props: {
    id: {
      required: true,
      type: Number,
    },
    snippet: String,
    thumbnail: String,
    user: {
      required: true,
      type: Object as PropType<UserFeatured>,
    },
    url: {
      type: String,
      required: true,
    },
    title: String,
    action_status: {
      type: Number as PropType<ActionStatus>,
    },
    status: {
      type: String as PropType<ValidationStatus>,
      required: true,
    },
    questionId: {
      type: Number,
      required: true,
    },
    created_by_creator: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      isLoading: false,
      deleting: false,
      error: "",
      currentUrl: this.url,
      previousUrl: this.url,
      currentStatus: this.status,
      currentResource: {
        snippet: this.snippet,
        id: this.id,
        url: this.url,
        title: this.title,
        thumbnail: this.thumbnail,
        action_status: this.action_status,
      } as Resource,
    };
  },
  methods: {
    onEdit() {
      this.isEditing = true;
    },
    async onDelete() {
      try {
        this.currentResource.action_status = ActionStatus.DELETED;
        this.isEditing = false;
        await deleteResource(await getAuthToken(), this.id);
        setTimeout(() => {
          this.$emit("onDelete");
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    },
    onClose() {
      this.isEditing = false;
      this.isLoading = false;
      this.error = "";
      this.currentUrl = this.previousUrl;
    },
    async onSubmit() {
      this.isLoading = true;
      this.error = "";
      try {
        if (!validateURL(this.currentUrl)) {
          throw new Error("Paste a valid URL");
        }
        this.currentResource = await updateResource(
          await getAuthToken(),
          this.id,
          { url: this.currentUrl, question_id: this.questionId }
        );
        this.currentResource.action_status = ActionStatus.EDITED;
        this.isEditing = false;
        this.error = "";
      } catch (err) {
        this.error = getErrorMessage(err);
      }
      this.isLoading = false;
    },
  },
  computed: {
    backgroundClass(): string {
      switch (this.currentResource.action_status) {
        case ActionStatus.ADDED:
          return "card-highlight";
        case ActionStatus.EDITED:
          return "card-highlight";
        case ActionStatus.DELETED:
          return "red-bg-soft";
        default:
          return "";
      }
    },

    showAvatarDefaultImage(): boolean {
      if (this.user) {
        return (
          hasPriviledgedRole(this.user.role) &&
          this.$store.getters.getUserID !== this.user.uid
        );
      }
      return false;
    },
    hasEditPermission(): boolean {
      return hasEditPermission(
        this.$store.getters.getUserID,
        this.user.uid,
        this.$store.getters.getUserRole
      );
    },
  },
});
