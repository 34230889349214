
import { getAuthToken } from "@/utils/auth";
import { createResource } from "@toppick/common/build/api/resource";
import { getErrorMessage } from "@toppick/common/build/utils";
import {
  validatePassword,
  validateURL,
} from "@toppick/common/build/validators";
import { defineComponent } from "@vue/runtime-core";
import SubmitInput from "../SubmitInput.vue";
export default defineComponent({
  components: { SubmitInput },
  emits: ["onSubmit", "onClose"],
  props: {
    questionId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error: "",
      url: "",
      isLoading: false,
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      this.error = "";
      validatePassword;
      try {
        if (!validateURL(this.url)) {
          throw new Error("Paste a valid URL");
        }
        const createdResource = await createResource(await getAuthToken(), {
          url: this.url,
          question_id: this.questionId,
        });
        this.$emit("onSubmit", createdResource);
        this.error = "";
        this.url = "";
      } catch (err) {
        this.error = getErrorMessage(err);
      }
      this.isLoading = false;
    },
    onClose() {
      this.$emit("onClose");
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUserFeatured;
    },
  },
});
