import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "submit_rich_text" }
const _hoisted_2 = { class: "answer_input_outer mt-2" }
const _hoisted_3 = { style: {"width":"45px"} }
const _hoisted_4 = { class: "add_input_area show" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "text-left text_submit_buttons" }
const _hoisted_7 = { class: "text-danger mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_avatar = _resolveComponent("user-avatar")!
  const _component_editor = _resolveComponent("editor")!
  const _component_char_counter = _resolveComponent("char-counter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_user_avatar, { user: _ctx.user }, null, 8, ["user"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_editor, {
            placeholder: _ctx.placeholder,
            modelValue: _ctx.modelValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
          }, null, 8, ["placeholder", "modelValue"]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
            }, "Close"),
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSubmit()))
            }, "Submit"),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.error), 1)
          ])
        ]),
        _createVNode(_component_char_counter, {
          maxValue: 500,
          text: _ctx.modelValue
        }, null, 8, ["text"])
      ])
    ])
  ]))
}