
import { UserFeatured } from "@toppick/common/build/interfaces";
import { defineComponent, PropType } from "vue";
import UserAvatar from "../ui/UserAvatar.vue";

export default defineComponent({
  components: { UserAvatar },
  emits: ["onSubmit", "onClose", "update:modelValue"],
  props: {
    error: String,
    placeholder: String,
    modelValue: String,
    isLoading: Boolean,
    user: {
      required: true,
      type: Object as PropType<UserFeatured>,
    },
  },
  methods: {
    onSubmit() {
      this.$emit("onSubmit");
    },
    onClose() {
      this.$emit("onClose");
    },
  },
});
