
import { parseHtml } from "@/utils/utils";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    maxValue: {
      required: true,
      type: Number,
    },
    text: {
      required: true,
      type: String,
    },
  },
  computed: {
    currentValue(): number {
      return parseHtml(this.text).length;
    },
  },
});
