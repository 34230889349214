import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/pencil.svg'


const _hoisted_1 = {
  key: 1,
  class: "exp_inputArea"
}
const _hoisted_2 = ["placeholder", "value"]
const _hoisted_3 = { class: "text-danger error_input" }
const _hoisted_4 = ["id"]
const _hoisted_5 = {
  key: 3,
  class: "accord-item-edit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_avatar = _resolveComponent("user-avatar")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showAvatar && _ctx.user)
      ? (_openBlock(), _createBlock(_component_user_avatar, {
          key: 0,
          user: _ctx.user,
          showPreview: true,
          showDefaultImage: _ctx.showAvatarDefaultImage
        }, null, 8, ["user", "showDefaultImage"]))
      : _createCommentVNode("", true),
    (_ctx.isEditing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: _normalizeClass('answerHidden ' + _ctx.backgroundClass),
            placeholder: _ctx.placeholder,
            value: _ctx.modelValue,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickInput && _ctx.onClickInput(...args))),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
            onKeyup: [
              _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["enter"])),
              _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onKeySpace && _ctx.onKeySpace(...args)), ["space"]))
            ]
          }, null, 42, _hoisted_2), [
            [_directive_focus]
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["accord_btn_list", { show: _ctx.isEditing && !_ctx.isLoading }])
          }, [
            _createElementVNode("button", {
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args))),
              class: "cancelbtn cmn-accord-btn"
            }, "Close"),
            (_ctx.showDelete)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onDelete && _ctx.onDelete(...args))),
                  class: "deletebtn cmn-accord-btn"
                }, " Delete "))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args))),
              class: "savebtn cmn-accord-btn"
            }, "Update"),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.error), 1)
          ], 2)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isEditing)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass('title ' + _ctx.backgroundClass)
        }, [
          (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass([_ctx.showTranslation ? 'translate' : 'no-translate']),
            key: _ctx.showTranslation,
            id: _ctx.inputFieldId
          }, _toDisplayString(_ctx.modelValue), 11, _hoisted_4))
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.editable)
      ? (_openBlock(), _createElementBlock("button", _hoisted_5, [
          (!_ctx.isEditing)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _imports_0,
                class: "pencilEdit",
                alt: "",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onEdit()))
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}