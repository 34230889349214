
import { getAuthToken } from "@/utils/auth";
import { parseHtml } from "@/utils/utils";
import { createAnswer } from "@toppick/common/build/api/answer";
import { getErrorMessage } from "@toppick/common/build/utils";
import {
  validateAnswerTitleMin,
  validateAnswerTitleMax,
} from "@toppick/common/build/validators";
import { defineComponent } from "@vue/runtime-core";
import SubmitTextarea from "../SubmitTextarea.vue";

export default defineComponent({
  components: { SubmitTextarea },
  emits: ["onSubmit", "onClose"],
  props: {
    questionId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      title: "",
      error: "",
      isLoading: false,
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      this.error = "";
      try {
        if (!validateAnswerTitleMin(parseHtml(this.title))) {
          throw new Error("Answer should be longer");
        }

        if (!validateAnswerTitleMax(parseHtml(this.title))) {
          throw new Error("Answer should be shorter");
        }

        const createdAnswer = await createAnswer(await getAuthToken(), {
          title: this.title,
          question_id: this.questionId,
        });

        this.$emit("onSubmit", createdAnswer);
        this.error = "";
      } catch (err) {
        this.error = getErrorMessage(err);
      }
      this.isLoading = false;
    },
    onClose() {
      this.$emit("onClose");
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUserFeatured;
    },
  },
});
