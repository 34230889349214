import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_submit_textarea = _resolveComponent("submit-textarea")!

  return (_openBlock(), _createBlock(_component_submit_textarea, {
    error: _ctx.error,
    onOnSubmit: _ctx.onSubmit,
    onOnClose: _ctx.onClose,
    user: _ctx.currentUser,
    isLoading: _ctx.isLoading,
    modelValue: _ctx.title,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
    placeholder: "Write a new answer"
  }, null, 8, ["error", "onOnSubmit", "onOnClose", "user", "isLoading", "modelValue"]))
}