
import { QuillEditor, Quill } from "@vueup/vue-quill";
import { defineComponent } from "vue";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { parseHtml } from "@/utils/utils";

const Link = Quill.import("formats/link");

class MyLink extends Link {
  static create(value) {
    let node = super.create(value);
    value = this.sanitize(value);
    if (!value.startsWith("http")) {
      value = "https://" + value;
    }
    node.setAttribute("href", value);
    return node;
  }
}

Quill.register(MyLink);

const toolbarTooltips = {
  font: "Select a font",
  size: "Select a font size",
  header: "Select the text style",
  bold: "Bold",
  italic: "Italic",
  underline: "Underline",
  strike: "Strikethrough",
  color: "Select a text color",
  background: "Select a background color",
  script: {
    sub: "Subscript",
    super: "Superscript",
  },
  list: {
    ordered: "Numbered list",
    bullet: "Bulleted list",
  },
  indent: {
    "-1": "Decrease indent",
    "+1": "Increase indent",
  },
  direction: {
    rtl: "Text direction (right to left | left to right)",
    ltr: "Text direction (left ro right | right to left)",
  },
  align: "Text alignment",
  link: "Insert a link",
  image: "Insert an image",
  formula: "Insert a formula",
  clean: "Clear Formatting",
  "add-table": "Add a new table",
  "table-row": "Add a row to the selected table",
  "table-column": "Add a column to the selected table",
  "remove-table": "Remove selected table",
  help: "Show help",
};

function showTooltips() {
  let showTooltip = (which, el) => {
    let tool;
    if (which == "button") {
      tool = el.className.replace("ql-", "");
    } else if (which == "span") {
      tool = el.className.replace("ql-", "");
      tool = tool.substr(0, tool.indexOf(" "));
    }
    if (tool) {
      if (el.value == "") {
        if (toolbarTooltips[tool])
          el.setAttribute("data-title", toolbarTooltips[tool]);
      } else if (typeof el.value !== "undefined") {
        if (toolbarTooltips[tool][el.value])
          el.setAttribute("data-title", toolbarTooltips[tool][el.value]);
      } else el.setAttribute("data-title", toolbarTooltips[tool]);
    }
  };

  let toolbarElement = document.querySelector(".ql-toolbar");
  if (toolbarElement) {
    let matchesButtons = toolbarElement.querySelectorAll("button");
    for (let el of matchesButtons) {
      showTooltip("button", el);
    }
    let matchesSpans = toolbarElement.querySelectorAll(
      ".ql-toolbar > span > span"
    );
    for (let el of matchesSpans) {
      showTooltip("span", el);
    }
  }
}
export default defineComponent({
  components: { QuillEditor },
  emits: ["update:modelValue"],
  props: {
    placeholder: String,
    modelValue: String,
  },
  methods: {
    onReady(editor) {
      editor.focus();
      //set current cursor position. getSelection required for working(?)
      editor.setSelection(
        editor.getSelection().index + parseHtml(this.modelValue || "").length,
        0
      );
    },
  },
  mounted() {
    showTooltips();
  },
});
