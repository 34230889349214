
import { defineComponent, PropType } from "vue";
import { Resource, ValidationAction } from "@toppick/common/build/interfaces";
import { validateResource } from "@toppick/common/build/api/resource";
import UserAvatar from "@/components/ui/UserAvatar.vue";
import "../styles.css";
import { getAuthToken } from "@/utils/auth";

export default defineComponent({
  emits: ["loadResource"],
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
  },
  components: { UserAvatar },
  data() {
    return {
      ValidationAction,
    };
  },
  methods: {
    async onValidate(action: ValidationAction) {
      try {
        await validateResource(await getAuthToken(), this.resource.id, action);
        this.$emit("loadResource");
      } catch (error) {
        console.log(error);
      }
    },
  },
});
