
import { PropType } from "vue";
import { ActionStatus } from "@/interfaces/ui";
import {
  UserFeatured,
  ValidationStatus,
} from "@toppick/common/build/interfaces";
import { defineComponent } from "@vue/runtime-core";
import { deleteAnswer, updateAnswer } from "@toppick/common/build/api/answer";
import { getAuthToken, hasEditPermission } from "@/utils/auth";
import StatusPointedBadge from "../../ui/badges/StatusPointedBadge.vue";
import {
  validateAnswerTitleMax,
  validateAnswerTitleMin,
} from "@toppick/common/build/validators";
import EditTextarea from "../EditTextarea.vue";
import { getErrorMessage } from "@toppick/common/build/utils";
import { parseHtml } from "@/utils/utils";

export default defineComponent({
  emits: ["onDelete"],
  props: {
    id: {
      required: true,
      type: Number,
    },
    title: {
      required: true,
      type: String,
    },
    user: {
      required: true,
      type: Object as PropType<UserFeatured>,
    },
    action_status: {
      type: Number as PropType<ActionStatus>,
    },
    status: {
      type: String as PropType<ValidationStatus>,
      required: true,
    },
    questionId: {
      type: Number,
      required: true,
    },
    showTranslation: {
      type: Boolean,
      required: false,
    },
    created_by_creator: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    StatusPointedBadge,
    EditTextarea,
  },
  data() {
    return {
      isEditing: false,
      isLoading: false,
      deleting: false,
      currentTitle: this.title,
      previousTitle: this.title,
      currentActionStatus: this.action_status,
      currentStatus: this.status,
      error: "",
    };
  },
  methods: {
    onEdit() {
      this.isEditing = true;
    },
    async onSubmit() {
      this.isLoading = true;
      this.error = "";
      console.log("result", this.currentTitle, this.currentTitle.length);
      try {
        if (!validateAnswerTitleMin(parseHtml(this.currentTitle))) {
          throw new Error("Answer should be longer");
        }

        if (!validateAnswerTitleMax(parseHtml(this.currentTitle))) {
          throw new Error("Answer should be shorter");
        }

        const answer = await updateAnswer(await getAuthToken(), this.id, {
          title: this.currentTitle,
          question_id: this.questionId,
        });

        this.currentActionStatus = ActionStatus.EDITED;
        this.currentStatus = answer.status!;
        this.previousTitle = this.currentTitle;
        this.isEditing = false;
        this.error = "";
      } catch (err) {
        this.error = getErrorMessage(err);
      }
      this.isLoading = false;
    },

    async onDelete() {
      try {
        this.currentActionStatus = ActionStatus.DELETED;
        this.isEditing = false;
        await deleteAnswer(await getAuthToken(), this.id);
        setTimeout(() => {
          this.$emit("onDelete");
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    },

    onClose() {
      this.isEditing = false;
      this.isLoading = false;
      this.error = "";
      this.currentTitle = this.previousTitle;
    },
  },
  computed: {
    editable() {
      return true;
    },

    hasEditPermission(): boolean {
      return hasEditPermission(
        this.$store.getters.getUserID,
        this.user.uid,
        this.$store.getters.getUserRole
      );
    },
    backgroundClass(): string {
      switch (this.currentActionStatus) {
        case ActionStatus.ADDED:
          return "card-highlight";
        case ActionStatus.EDITED:
          return "card-highlight";
        case ActionStatus.DELETED:
          return "red-bg-soft";
        default:
          return "";
      }
    },
  },
});
