
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";
import { UserFeatured } from "@toppick/common/build/interfaces";
import UserAvatar from "../ui/UserAvatar.vue";
import Editor from "../ui/Editor.vue";
import CharCounter from "../ui/CharCounter.vue";

export default defineComponent({
  components: { UserAvatar, Editor, CharCounter },
  emits: ["onSubmit", "onClose", "onEdit", "onDelete", "update:modelValue"],
  props: {
    error: String,
    placeholder: String,
    modelValue: String,
    isLoading: Boolean,
    isEditing: Boolean,
    editable: Boolean,
    backgroundClass: String,
    showDelete: {
      required: false,
      default: true,
      type: Boolean,
    },
    showAvatar: {
      required: false,
      default: true,
      type: Boolean,
    },
    user: {
      required: false,
      type: Object as PropType<UserFeatured>,
    },
    showTranslation: {
      required: false,
      type: Boolean,
    },
    created_by_creator: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    onSubmit(e) {
      this.$emit("onSubmit");
      e.stopPropagation();
    },
    onClickInput(e) {
      if (this.isEditing) e.stopPropagation();
    },
    onKeySpace(e) {
      e.preventDefault();
    },
    onClose(e) {
      this.$emit("onClose");
      e.stopPropagation();
    },

    onEdit() {
      this.$emit("onEdit");
    },
    onDelete(e) {
      this.$emit("onDelete");
      e.stopPropagation();
    },
  },
  computed: {
    showAvatarDefaultImage(): boolean {
      if (this.user) {
        return (
          this.created_by_creator &&
          this.$store.getters.getUserID !== this.user.uid
        );
      }
      return false;
    },
  },
});
