import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuillEditor = _resolveComponent("QuillEditor")!

  return (_openBlock(), _createBlock(_component_QuillEditor, {
    theme: "snow",
    placeholder: _ctx.placeholder,
    content: _ctx.modelValue,
    "onUpdate:content": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    contentType: "html",
    onReady: _ctx.onReady
  }, null, 8, ["placeholder", "content", "onReady"]))
}