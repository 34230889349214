
import { defineComponent, PropType } from "vue";
import { Answer, ValidationAction } from "@toppick/common/build/interfaces";
import { validateAnswer } from "@toppick/common/build/api/answer";
import UserAvatar from "@/components/ui/UserAvatar.vue";
import "../styles.css";
import { getAuthToken } from "@/utils/auth";
export default defineComponent({
  emits: ["loadAnswer"],
  props: {
    answer: {
      type: Object as PropType<Answer>,
      required: true,
    },
  },
  components: { UserAvatar },
  data() {
    return {
      ValidationAction,
    };
  },
  methods: {
    async onValidate(action: ValidationAction) {
      try {
        await validateAnswer(await getAuthToken(), this.answer.id, action);
        this.$emit("loadAnswer");
      } catch (error) {
        console.log(error);
      }
    },
  },
});
